import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'

function MyNavbar() {

  const outerNavbarBorder = {
    borderTop: '10px solid #bc5656',
    borderBottom: '10px solid #bc5656',
    marginLeft: '-1%',
    width: '102%',
    paddingLeft: '1%',
  };

  const innerNavbarBorder = {
    borderTop: '4px solid white',
    borderBottom: '4px solid white',
    marginLeft: '-1%',
    width: '102%',
    paddingLeft: '1%',
  };

  return (
    <div style={outerNavbarBorder} >
      <div style={innerNavbarBorder}>
        <Navbar className="navbar-color" expand="xl" sticky="top">
          <Container>
            <Navbar.Brand href="/">Zauberschön</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Behandlungen" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/Behandlungen/">Übersicht</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/Behandlungen/Stammzellen/">Stammzellen Technologie</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Hyaluronbehandlung">Hyaluronbehandlung</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Golden-Skin">Golden-Skin-Behandlung</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Energy-Vitamin-Power">Energy-Vitamin-Power</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Intensivbehandlungen">Intensivbehandlungen</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Pure-Behandlung">"Pure"-Fruchtsäurebehandlung</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Tiefenreinigung">Tiefenreinigung</NavDropdown.Item>
                  <NavDropdown.Item href="/Behandlungen/Lymphdrainage">Kosmetische Lymphdrainage</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Apparative Behandlungen" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/Apparative-Behandlungen/">Übersicht</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/Apparative-Behandlungen/Mesoporation/">Mesoporation</NavDropdown.Item>
                  <NavDropdown.Item href="/Apparative-Behandlungen/Microdermabrasion">Microdermabrasion</NavDropdown.Item>
                  <NavDropdown.Item href="/Apparative-Behandlungen/Specials">Specials</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/Extras">Extras</Nav.Link>
                <Nav.Link href="/Hand-Fuss-Koerper">Hand & Fuß & Körper</Nav.Link>
                <Nav.Link href="/Anfahrt">Anfahrt</Nav.Link>
                <Nav.Link href="/Kontakt">Kontakt</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}

export default MyNavbar;
