import { Col, Container, Row } from "react-bootstrap";
import { Nav } from "react-bootstrap";

export default function Behandlungen() {
  return (
    <div>
      <Container fluid>
        <h1>Behandlungen</h1>
        <hr />
        <Row xs={1} md={3}>
          <Col>
            <h2>
              <Nav.Link href="Stammzellen">Stammzellen Technologie</Nav.Link>
            </h2>
          </Col>
          <Col>
            <h2>
              <Nav.Link href="Hyaluronbehandlung">Hyaluronbehandlung</Nav.Link>
            </h2>
          </Col>
          <Col>
            <h2>
              <Nav.Link href="Golden-Skin">Golden-Skin-Behandlung</Nav.Link>
            </h2>
          </Col>
        </Row>
        <hr />
        <Row xs={1} md={3}>
          <Col>
            <h2>
              <Nav.Link href="Energy-Vitamin-Power">Energy-Vitamin-Power</Nav.Link>
            </h2>
          </Col>
          <Col>
            <h2>
              <Nav.Link href="Intensivbehandlungen">Intensivbehandlungen</Nav.Link>
            </h2>
          </Col>
          <Col>
            <h2>
              <Nav.Link href="Pure-Behandlung">„Pure“-Fruchtsäurebehandlung</Nav.Link>
            </h2>
          </Col>
        </Row>
        <hr />
        <Row xs={1} md={2}>
          <Col>
            <h2>
              <Nav.Link href="Tiefenreinigung">Tiefenreinigung</Nav.Link>
            </h2>
          </Col>
          <Col>
            <h2>
              <Nav.Link href="Lymphdrainage">Kosmetische Lymphdrainage</Nav.Link>
            </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
