import { Col, Row } from "react-bootstrap";
import MainSlogan from "./components/mainSlogan";
import TreatmentIncludes from "./components/treatmentIncludes";

export default function Intensiv() {
  return (
    <div>
      <h1>Intensivbehandlungen</h1>
      <Row>
        <Col>
          <h2>Sensiplusbehandlung</h2>
          <p className="App-left-align">
            Ausgleichende, schutzmechanismusaktivierende und besänftigende Feuchtigkeitsbehandlung mit Seidenproteinen, Ceramiden & Pflanzenextrakten.
          </p>
          <p className="App-left-align">
            Als Schaumcrememaske in die Hauttiefe mit Thermostimulation eingearbeitet.
          </p>
          <p className="App-left-align">
            Ohne Farb- und Konservierungsstoffe & Mineralöle
          </p>
          <p className="priceTag">89 €</p>
        </Col>
        <Col>
          <h2>Kalt-Modellagebehandlung</h2>
          <div className="App-left-align">
            <p>
              Intensive Feuchtigkeitsbehandlung mit Alginat und modellierenden Eigenschaften. Gezielt einsetzbare Wirkstoffkomponenten für verschiedene Hautbilder.
            </p>
            <ul>
              <li><b>Acerola</b> - feuchtigkeitsarme, sehr sensible Haut</li>
              <li><b>Bilberry</b> - leicht reizbare Haut</li>
              <li><b>Sea Mud</b> - Akne Hautbilder</li>
              <li><b>Whitening</b> - Pigmentstörungen, Couperosehautbilder</li>
            </ul>
          </div>
          <p className="priceTag">79 €</p>
        </Col>
        <Col>
          <h2>Vliesbehandlungen</h2>
          <div className="App-left-align">
            <p>
              Intensive Feuchtigkeitsbehandlung mit Alginat und modellierenden Eigenschaften. Gezielt einsetzbare Wirkstoffkomponenten für verschiedene Hautbilder.
            </p>
            <ul>
              <li><b>Rotweinhydrovlies</b> - Sanft belebende Collagenaufbaubehandlung mit Zellschutzfunktion</li>
              <li><b>Vitamin C Hydrovlies</b> - aufbauende vitalisierende Vitaminzufuhr</li>
              <li><b>Phyto Hydrovlies</b> - anregende zellaktivierende Behandlung durch Pflanzenhormone</li>
              <li><b>Collagen Elastin Hydrovlies</b> - feuchtigkeitsbindende Wirkstoffbehandlung zur Stärkung des Fasernetzwerkes im Gewebe</li>
            </ul>
          </div>
          <p className="priceTag">79 €</p>

        </Col>
      </Row>
      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung & Peeling oder Enzympeeling', 'Hautbedampfung', 'Entfernen von Unreinheiten', 'Augenbrauenkorrektur', 'Maske', 'verzaubernde Gesichts-, Hals-, Nacken- und Decolletemassage']}
        time={90}
      />
    </div>
  );
}
