import { useState } from 'react';
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion'


function OffCanvasExample({ name, ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="outline-dark" onClick={handleShow} size="sm">
                Gutschein für diese Behandlung
            </Button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Gutschein für diese Behandlung</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>Ab sofort gibt es Gutscheine für alle Behandlungen online!</p>
                    <Button href="/checkout"> Order </Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default function TreatmentIncludes4(props) {
    return (
        <p className="App-left-align">
            <Accordion >
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="treatment-includes-header">Die Behandlung beinhaltet:</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant="flush">
                            {props.list.map(myStuff => {
                                return <ListGroup.Item>{myStuff}</ListGroup.Item>
                            })}
                        </ListGroup>
                        <br />
                        {props.time != null &&
                            <span>(Verwöhndauer: ca. {props.time} min)</span>
                        }

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {props.price != null &&
                <p className="priceTag">{props.price} €</p>
            }
            <p>Ab sofort sind Gutscheine für alle Behandlungen online hier erhältlich! </p><OffCanvasExample placement='top' />
        </p>
    );
}
