import { Col, Row, Form, Button } from "react-bootstrap";
import { useState } from "react";


function Contact() {

  const [buttonText, buttonTextSet] = useState("Nachricht absenden")
  const [buttonDisabled, buttonDisabledSet] = useState(false)
  const [buttonVariant, buttonVariantSet] = useState("outline-dark");


  function ContactForm() {

    async function sendMail() {
      var axios = require('axios');
      var data = JSON.stringify({
        "sender": email,
        "subject": subject,
        "message": message,
        "name": name
      });

      var config = {
        method: 'post',
        url: 'https://4y5yy3psqd.execute-api.eu-central-1.amazonaws.com/zauberschoenSendMail',
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        },
        data: data
      };

      await axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          buttonTextSet("Nachricht versendet");
        })
        .catch(function (error) {
          console.log(error);
          buttonVariantSet("outline-danger");
          buttonTextSet("Fehler - Bitte versuchen Sie es per Telefon");
        });
      buttonDisabledSet(true);
    };

    var name = "";
    var email = "";
    var subject = "";
    var message = "";

    return (
      <>
        <Form >
          <Form.Group className="mb-3" controlId="form.name">
            <Form.Label>Mein Name</Form.Label>
            <Form.Control type="value" placeholder="Ich bin..." onChange={(e) => name = e.target.value} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.email">
            <Form.Label>Meine Email Adresse</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" onChange={(e) => email = e.target.value} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.email">
            <Form.Label>Meine Nachricht betrifft eine</Form.Label>
            <Form.Select aria-label="Default select example" onChange={(e) => subject = e.target.value}>
              <option value="Nachricht">... bitte wählen</option>
              <option value="Eine allgemeine Frage">Allgemeine Frage</option>
              <option value="Terminanfrage">Terminanfrage</option>
              <option value="Rückmeldung">Rückmeldung</option>
            </Form.Select>          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Meine Nachricht</Form.Label>
            <Form.Control as="textarea" rows={4} onChange={(e) => message = e.target.value} />
          </Form.Group>
          <Button variant={buttonVariant} onClick={() => sendMail()} disabled={buttonDisabled}>{buttonText}</Button>
        </Form>

      </>
    )
  }



  return (
    <div className="App-left-align mb-3">
      <h1>Kontakt</h1>
      <Row xs={1} md={2} >
        <Col>
          <p>
            Wir freuen uns über Fragen, Anregungen, Feedback und natürlich über Terminanfragen.
          </p>
          <p>
            <b>Carolin Cornet</b><br />
            Tel.: +49 30 88062501
          </p>
          <hr />
          <p>
            <b>Öffnungszeiten:</b>
          </p>
          <p>
            Mo - Do: 9°° - 16°° Uhr<br />
            Fr - Sa: 9°° - 20°° Uhr
          </p>
          <hr />
          <p>
            Wenn Sie ihren Termin nicht wahrnehmen können, informieren Sie uns bitte rechtzeitig (mind. 24 Stunden vorher). Bei kurzfristig abgesagten Terminen werden 25% der gebuchten Behandlung berechnet - auch bei kurzfristig vereinbarten Terminen.    </p>
        </Col>
        <Col>
          <ContactForm />
        </Col>
      </Row >
    </div>
  );
}

export default Contact;
