import { Container, Row, Col } from "react-bootstrap";

export default function Welcome() {
  return (
    <Container>
      <Row className="justify-content-center">
        <h1>Willkommen bei Zauberschön Kosmetik</h1>
        <Col lg="8">
          <p>
          Maßgeschneiderte Behandlungen eigens auf Ihr Hautbild abgestimmte Kosmetikprodukte in der Behandlung und auch zum Heimbedarf.
            </p>
          <p>
            Lassen Sie sich verzaubern…
          </p>
          <p>
            Um die Schönheit jeder Person hervorzuheben setzen wir den Schwerpunkt beim gezielten Einsetzen von professionellen Kosmetikprodukten der Firma Etre Belle und LCN.
          </p>
          <p>
            Individuell und systematisch für Ihre Haut, Hände und Füße werden abgestimmte Behandlungsmethoden für Sie und Ihn angeboten.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
