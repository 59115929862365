import TreatmentIncludes from "./components/treatmentIncludes";

export default function PureTreatment() {
  return (
    <div>
      <h1>„Pure“- Fruchtsäurebehandlung</h1>
      <p><b>Das hochentwickelte Pure White System steht für „Makellosigkeit“</b></p>
      <div className="App-left-align">
        <p>
          Die ausgewählten Wirkstoffe blockieren die Melaninausschüttung und bremsen Verfärbungen. Der Abbau dieser  Flecken wird zudem rasch abgebaut. Die Haut wird entgiftet und erneuert. Daraus folgt eine moderne ebenmäßige Porzellanhaut für Sie.
        </p>
        <p>
          Sehr gute Verträglichkeit und anwendbar bei atrophischer, trockener/fahler Haut. Bei Pigmentflecken, Fältchen sowie gegen Aknenarben.
        </p>
        <p>
          Da Schlüsselreize mit hoher Intensität erzielt werden, ist eine Kurbehandlung von 5 Wochen mit einer Sitzung pro Woche für optimale Erfolge empfehlenswert.
        </p>
        <p>
          Als Kur oder zu einer Kosmetikbehandlung zubuchbar. (Verwöhndauer ca. 30 Min – 59 €)
        </p>
      </div>

      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung', 'Spezialfruchtsäurepeeling', 'Entfernen von Unreinheiten', 'Augenbrauenkorrektur', 'Feuchtigkeitsmaske & eine verzaubernde angenehme Lymphdrainage']}
        time={60}
        price={89}
      />
    </div>
  );
}
