import { Col, Row } from "react-bootstrap";
import { Nav } from "react-bootstrap";

export default function ApparativeBehandlungen() {
  return (
    <div>
      <h1>Apparative Behandlungen</h1>
      <hr />
      <Row xs={1} md={3}>
        <Col>
          <h2>
            <Nav.Link href="Mesoporation">Mesoporation</Nav.Link>
          </h2>
        </Col>
        <Col>
          <h2>
            <Nav.Link href="Microdermabrasion">Microdermabrasion</Nav.Link>
          </h2>
        </Col>
        <Col>
          <h2>
            <Nav.Link href="Specials">Specials</Nav.Link>
          </h2>
        </Col>
      </Row>
      <hr />
    </div>
  );
}
