import TreatmentIncludes from "./components/treatmentIncludes";

export default function Lymphdrainage() {
  return (
    <div>
      <h1>Kosmetische Lymphdrainage</h1>
      <p><b>Manuelle Lymphdrainage für das Gesicht</b></p>
      <div className="App-left-align">
        <p>
          Durch die manuelle Lymphdrainage wird der Abtransport der Lymphe  über deren Gefäße angeregt, das Gewebe wird endstaut. Bei starker Neigung zu Rötungen, Allergien, Sensibilität und Unreinheiten sowie Schwellungen, nach Schönheitsoperationen und auch Aknehautbilder werden optimal therapiert.        </p>
        <p>
          innerhalb der Kosmetikbehandlung – Behandlungszeit:    15 Minuten – 28 €

        </p>
        <p>
          außerhalb der Kosmetikbehandlung – Behandlungszeit:  25 Minuten – 36 €    (inkl. Abreinigung und Tagespflege)
        </p>
        <p>
          <i>
            Diese Behandlung wird ihnen je nach Hautzustand innerhalb einer Kosmetikanwendung, als Einzeltermin und auch als Kur angeboten.
          </i>
        </p>
      </div>
    </div>
  );
}
