import { Col, Row } from "react-bootstrap";

export default function Navigation() {
  return (
    <div className="App-left-align">
      <h1>Anfahrt</h1>
      <Row>
        <Col>
          <p><b>Anfahrt mit dem Auto:</b></p>
          <p>
            <i>
              Zauberschön Kosmetik<br />
              Oderberger Str. 37<br />
              10435 Berlin<br />
            </i>
          </p>
          <hr />
          <p>
            <b>Anfahrt mit den Öffentlichen:</b>
          </p>
          <p>
            <b>U-Bahn</b>
            <p>U2 – Eberswalder Str.</p>
            <b>Tram</b>
            <p>
              M1 – Schwedter Str.<br />
              M2 – Friedrich-Ludwig-Jahn-Sportpark<br />
              M10 – Friedrich-Ludwig-Jahn-Sportpark<br />
              12 – Schwedter Str.<br />
            </p>
          </p>
          <hr />
        </Col>
        <Col>
        <p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2426.6456679597345!2d13.405776700000006!3d52.53984480000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851f91bdce5a1%3A0xcaa91a94f50c6263!2sOderberger+Stra%C3%9Fe+37!5e0!3m2!1sde!2sde!4v1400614243235" width="460" height="450" frameborder="0">
            </iframe>
          </p>
        </Col>
      </Row>
    </div>
  );
}
