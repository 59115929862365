import { Table } from "react-bootstrap";

export default function Specials() {
  return (
    <div className="App-content">
      <h1>Specials</h1>

      <div className="App-left-align">
        <h2>
          Ultraschall
        </h2>
        <p>
          Der Zellstoffwechsel, die Elastizität und die Aufnahme der Wirkstoffe in die Haut wird erhöht. Die Haut wird aufgepolstert, Falten minimieren sich. Einlagerungen und Hautgifte werden abgebaut. Die Gefäßwände werden stabilisiert.
        </p>
        <p>
          <h2>
            Schwungwellentherapie
          </h2>
          <ul>
            <li>
              Kaviation als Peeling :  sanfte sehr intensive Peelingmethode durch Schallwellenabtragung der Verhornungen und Unreinheiten.
            </li>
            <li>
              Kaviation als Wirkstoffeinschleusung :  Mikromassage bei Einschleusung der Wirkstoffe mit der Anhebung des Hautreliefs -Liftingeffekt
            </li>
          </ul>
        </p>
        <p>
          <h2>
            Hochfrequenztherapie
          </h2>
          Die Impulsivierung stimuliert die Durchblutung und ihren Lymphfluss. Die antiseptische Wirkung hilft Bakterien und Giftstoffe zu beseitigen.
        </p>
      </div>


      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >
              innerhalb der Kosmetikbehandlung – Behandlungszeit:
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je 10 min –  20 €</div></td>
          </tr>
          <tr>
            <td >
              außerhalb der Kosmetikbehandlung –  Behandlungszeit:<br />
              (inkl. Abreinigung und Tagespflege)
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je 15 min –   28 €</div></td>
          </tr>
        </tbody>
      </Table>
      <div className="App-left-align">
        (Bei der Ultraschall und Schwungwellenbehandlung kann ein Ampullenpräperat zugebucht werden. Alternativ wird mit einem wirkstofffreien Kontaktgel therapiert)
      </div>


    </div>
  );
}
