import { Table } from "react-bootstrap";

export default function Mikrodermabrasion() {
  return (
    <div className="App-content">
      <h1>Mikrodermabrasion</h1>

      <div className="App-left-align">
        <p>
          Die Mikrodermabrasion ist eine sehr effektive und schonende Behandlungsmethode zur Optimierung der Haut. Ein Verfahren bei dem Diamantkristalle mit einem Vakuumsystem sanft die obere Hautschicht der Epidermis abtragen. Diese Behandlungsmethode kann individuell auf jedes Hautbild abgestimmt werden.
        </p>
        <p>
          Die Behandlung wirkt stark zellerneuernd, damit porenverfeinernd und faltenreduzierend, punktuell eingesetzt pigmentaufhellend und narbenregulierend.
        </p>
      </div>


      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >
              Einzelbehandlung inklusive Abreinigung, Peeling & Abschlusspflege
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">58 €</div></td>
          </tr>
          <tr>
            <td >
              Innerhalb einer Kosmetikbehandlung
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">46 €</div></td>
          </tr>
        </tbody>
      </Table>

      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >
              als Kurbehandlung ( 5 Anwendungen )
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">275 €</div></td>
          </tr>
        </tbody>
      </Table>
      <div className="App-left-align">
        <p>
          Da Schlüsselreize auf die Zellteilung kontinuierlich und mit hoher Intensität einwirken sollen, ist eine Kurbehandlung von 4 Wochen mit einer Sitzung pro Woche für optimale Erfolge empfehlenswert.
        </p>
      </div>
    </div>
  );
}
