import { Table } from "react-bootstrap";

export default function Mesoporation() {
  return (
    <div className="App-content">
      <h1>Mesoporation</h1>

      <div className="App-left-align">
        <p>
          Die Mesoporation ist eine revolutionäre Behandlungsmethode bei der uns modernste Technologie ermöglicht
        </p>
        <ul>
          <li>
            Wirkstoffe nachhaltig in die Tiefe einzuschleusen
          </li>
          <li>
            Reduktion von Mimikfalten und Linien durch den Aufbau der Collagensynthese
          </li>
          <li>
            aufpolsternd und zugleich entstauend zu wirken
          </li>
        </ul>
        <p>
          Bei diesem Geräte wird mit Strom gearbeitet, der ermöglicht Hautkanäle zu öffnen und so tiefgreifend und nachhaltig wie nie zuvor die Haut aufzupolstern und das Hautrelief zu verfeinern.
        </p>
      </div>


      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >
              Einzelbehandlung inklusive Abreinigung & Abschlusspflege
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">78 €</div></td>
          </tr>
          <tr>
            <td >Innerhalb einer Kosmetikbehandlung</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">64 €</div></td>
          </tr>
        </tbody>
      </Table>


    </div>
  );
}
