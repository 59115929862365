import MainSlogan from "./components/mainSlogan";
import TreatmentIncludes from "./components/treatmentIncludes";

export default function EnergyVitaminPower() {
  return (
    <div>
      <h1>Energy-Vitamin-Power Behandlung</h1>
      <p><b>Vitamin A + E + C</b></p>
      <div className="App-left-align">
        <p>
          Die intensiv anregende fechtigkeitsbindende Behandlung mit „sofort Straffungseffekt“.
        </p>

        <p>
          Die Vitamine fördern die Erneuerungsprozesse und glätten sichtbar das Oberflächenrelief Ihrer Haut.
        </p>
        <p>
          Durch die wohlig, entspannende Fächerpinselmassage in der Behandlung wird die Wirkstoffaufnahme erhöht.
        </p>
      </div>

      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung', 'Peeling je nach Hautbild', 'Hautbedampfung', 'Entfernen von Unreinheiten', 'Augenbrauenkorrektur', 'Augenpflege', 'Effekt-Mousse Maske', 'Einarbeitung der Wirkstoffe mit Fächerpinselmassage ', 'verzaubernde Gesichts-, Hals-, Nacken- und Decolletemassage']}
        time={120}
        price={99}
      />
    </div>
  );
}
