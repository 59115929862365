import { Col, Container, Row, Table } from "react-bootstrap";

export default function HandFussKoerper() {
  return (
    <div className="App-left-align">
      <h1>Hand & Fuß & Körper</h1>
      <p>
        <Container>
          <Row xs={1} sm={2} >
            <Col  >
              <Table bordered className="App-left-align">
                <tbody>
                  <tr>
                    <td >Maniküre (ink. Fingerbad)</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">39 €</div></td>
                  </tr>
                  <tr>
                    <td >Wellnessmaniküre<br />
                      (inkl. Peel – Packung & pflegender Handmassage)</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">59 €</div></td>
                  </tr>
                  <tr>
                    <td >Lackieren</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">10 €</div></td>
                  </tr>
                  <tr>
                    <td >French – Lack</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">19 €</div></td>
                  </tr>
                  <tr>
                    <td >
                      Ablackieren
                    </td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">2,50 €</div></td>
                  </tr>
                  <tr>
                    <td >Pflegende Paraffinpackung</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">16 €</div></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col  >
              <Table bordered className="App-left-align">
                <tbody>
                  <tr>
                    <td >Pediküre (inkl. Fußbad)</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">ab 39 €</div></td>
                  </tr>
                  <tr>
                    <td >Wellnesspediküre<br />
                      (inkl. Peel – Packung & pflegender Fußmassage)</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">59 €</div></td>
                  </tr>
                  <tr>
                    <td >Lackieren</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">10 €</div></td>
                  </tr>
                  <tr>
                    <td >French – Lack</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">19 €</div></td>
                  </tr>
                  <tr>
                    <td >
                      Ablackieren
                    </td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">2,50 €</div></td>
                  </tr>
                  <tr>
                    <td >Pflegende Paraffinpackung</td>
                    <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">16 €</div></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <br />
          <h4>Einzelmassagen</h4>
          <Table bordered size="sm" className="App-left-align">
            <tbody>
              <tr>
                <td >Fuß- oder Handmassage mit Pflegecreme</td>
                <td className="App-right-align" class="col-md-3"><div className="noWarp-align-right">19 € <small>(je 10 Minuten)</small></div></td>
              </tr>
              <tr>
                <td >Fußreflexzonenmassage (Asiatica) wahlweise mit Fussbad oder Kompresse</td>
                <td className="App-right-align" class="col-md-3"><div className="noWarp-align-right">39 € <small>(30 Minuten)</small></div></td>
              </tr>
              <tr>
                <td >Rückenmassage mit Pflegeöl inkl. Nachruhezeit</td>
                <td className="App-right-align" class="col-md-3"><div className="noWarp-align-right">36 € <small>(30 Minuten)</small></div></td>
              </tr>
              <tr>
                <td >Hot Stone Rückenmassage inkl. Nachruhezeit</td>
                <td className="App-right-align" class="col-md-3"><div className="noWarp-align-right">64 € <small>(45 Minuten)</small></div></td>
              </tr>
            </tbody>
          </Table>
          <br />
          <h4>Enthaarung mit Warmwachs</h4>
          <Table bordered size="sm" className="App-left-align">
            <tbody>
              <tr>
                <td >Oberlippe, Kinn, Hals</td>
                <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je 10 €</div></td>
              </tr>
              <tr>
                <td >Achselzone, Bikinizone</td>
                <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je ab 18 €</div></td>
              </tr>
              <tr>
                <td >Unterschenkel, Oberschenkel</td>
                <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je 29 €</div></td>
              </tr>
              <tr>
                <td >Brust- oder Bauchenthaarung</td>
                <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">ab 29 €</div></td>
              </tr>
              <tr>
                <td >Rückenenthaarung</td>
                <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">ab 29 €</div></td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </p>
    </div>
  );
}
