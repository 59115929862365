import MainSlogan from "./components/mainSlogan";
import TreatmentIncludes from "./components/treatmentIncludes";

export default function Hyaluron() {
  return (
    <div>
      <h1>Hyaluronbehandlung</h1>
      <p><b>Hyaluron – „Tripple Effekt“</b></p>
      <MainSlogan text="3-fach aktiver Hyaluronsäurekomplex wirkt in einem intelligenten Mehrstufensystem. Traubenkernöl, Antioxidantien und Jojobaöl vollenden diese Komposition" />

      <p>
        Diese Behandlung überzeugt durch<br/>
        <b>Sofort + Langzeit + Lifting</b><br/>
        <b>Effekt</b>
      </p>

      <div className="App-left-align">
        <p>
          Durch die Einarbeitung der Wirkstoffe mit Ultraschall in der Behandlung erzielen wir einen Langzeiteffekt.
        </p>
      </div>
      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung', 'Peeling je nach Hautbild', 'Hautbedampfung', 'Entfernen von Unreinheiten', 'Augenbrauenkorrektur', 'Augenpflege-Spezial Roll on Behandlung', 'Effekt-Mousse Maske', 'Einschleusung der Wirkstoffe mit Ultraschall ', 'verzaubernde Gesichts-, Hals-, Nacken- und Decolletemassage']}
        time={120}
        price={109}
      />
    </div>
  );
}
