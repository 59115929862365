import Container from 'react-bootstrap/Container'
import { Outlet } from 'react-router';


import background from "./background.jpg";


import Header from '../components/header';
import Footer from '../components/footer'
import MyNavbar from '../components/myNavbar';


export default function BasePage() {
    return (
        <div style={{ backgroundImage: `url(${background})` }} className="App">
            <Header/>
            <Container className="App-content">
                <MyNavbar/>
                <Outlet />
            </Container>
            <Footer />
        </div>

    );
}
