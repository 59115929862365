import { Table } from "react-bootstrap";

import TreatmentIncludes from "./components/treatmentIncludes";

export default function Tiefenreinigung() {
  return (
    <div className="App-content">
      <h1>Tiefenreinigung</h1>
      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td ><b>Algenmodellage oder Algenvlies</b> (ja nach Hauttyp)<br />
              Die Wirkstoffe werden Hautbildspezifisch abgestimmt:<br />
              für seborrhoische Hautbilder – der Neigung zu Talg und / oder Fettüberproduktion, gegen Exzeme, Verhornungsstörungen und Couperose.</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">ab 59 €</div></td>
          </tr>
          <tr>
            <td ><b>Ausreinigungsbehandlung</b> für den Rücken oder Decolette</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">je ab 35 €</div></td>
          </tr>
        </tbody>
      </Table>
      <div className="App-left-align">

        <p>
          Zur Tiefenreinigung / Aknebehandlung empfehlen wir zur Optimierung als Therapieform nach Bedarf die Hochfrequenz-, Schwungwellen-, und auch Ultraschalltherapie sowie begleitend Lymphdrainage in und außerhalb der Behandlung.
        </p>
      </div>
      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung & Peeling oder Enzympeeling', 'Hauterweichung mit Wasserdampf', 'Entfernung von Unreinheiten', 'Augenbrauenkorrektur', 'Maske']}
      />

    </div>
  );
}
