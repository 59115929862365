import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'

import logo from '../logo.svg'
import paar from './paar.svg'

function Header() {
  return (
      <header >
      <img src={paar} className="App-logo" alt="logo" />
      </header>
  );
}

export default Header;
