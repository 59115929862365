import MainSlogan from "./components/mainSlogan";
import TreatmentIncludes from "./components/treatmentIncludes";

export default function Stammzellen() {
  return (
    <div>
      <h1>Stammzellen Technologie „Skinvision“</h1>
      <p>
      <b>Intensiv korrigierende Luxuspflege mit verjüngenden Stammzellen,<br/> Lifting-Peptiden, Platin & Botoxersatzstoffen.</b>
        </p>

      <div className="App-left-align">
        <p>
          Die Regenerierung der DNA der Zelle, der Drainage Effekt sowie das Erschaffen eines dreidemensionalen tiefenwirsamen Netzwerkes in der Haut sorgen bei dieser Powerbehandlung für:
          <ul>
            <li>Fülle</li>
            <li>Remodellierung &</li>
            <li>ein geliftetes straffes Hautgefühl</li>
          </ul>
        </p>
      </div>
      <TreatmentIncludes
        list={['Hautdiagnose', 'Reinigung', 'Peeling je nach Hautbild', 'Hautbedampfung', 'Entfernen von Unreinheiten', 'Augenbrauenkorrektur', 'Augenspezialpflege', 'marines Sprayelexir auch für zu Hause', 'Shakerstammzellenmaske', 'Liftserum ', 'eine verzaubernde Gesichts-, Hals-, Nacken- und Decollete Tiefengewebsmassage']}
        time={120}
        price={129}
      />
    </div>
  );
}
