import { Table } from "react-bootstrap";

export default function Extras() {
  return (
    <div className="App-content">
      <h1>Extras</h1>
      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >Augenbrauenstyling</td>
            <td class="col-md-1"><div className="noWarp-align-right">ab 12 €</div></td>
          </tr>
          <tr>
            <td >Wimpern färben</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">10 € / 13 €</div></td>
          </tr>
          <tr>
            <td >Augenbrauen färben</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">10 € / 13 €</div></td>
          </tr>
          <tr>
            <td >Oberlippenhärchen entfernen</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">10 €</div></td>
          </tr>
          <tr>
            <td >
              Wimpernwelle / Wimpernlifting
              <ul>
                <li>(für einen zauberhaften Augenaufschlag bis zu 8 Wochen)</li>
              </ul>
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">35 € / 38 €</div></td>
          </tr>
          <tr>
            <td >Spezial Wirkstoffampulle in der Behandlung<br />
              (Ampullenkuren sind auch für den Heimbedarf erhältlich)</td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">ab 10 €</div></td>
          </tr>
          <tr>
            <td >
              Augenintensivbehandlung
              <ul>
                <li>Hyaluron Augenbehandlung oder</li>
                <li>Spezialwirkstoff mit Ultraschalleinschleusung</li>
              </ul>
            </td>
            <td className="App-right-align" class="col-md-1"><div className="noWarp-align-right">22 €</div></td>
          </tr>
        </tbody>
      </Table>

      <br />
      <Table bordered size="sm" className="App-left-align">
        <tbody>
          <tr>
            <td >Innerhalb der Behandlung erhalten sie auf Wunsch gerne ein typgerechtes Tages Makeup</td>
            <td class="col-md-1"><div className="noWarp-align-right">ab  9 €</div></td>
          </tr>
          <tr>
            <td >Zu besonderen Anlässen auf Nachfrage</td>
            <td class="col-md-1"><div className="noWarp-align-right">ab 29 €</div></td>
          </tr>
        </tbody>
      </Table>
      <hr />
      <i>Auf Wunsch sind für Sie & Ihn Gutscheine über die Behandlung ihrer Wahl erhältlich.</i>
    </div>
  );
}
