import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import './App.css';

import BasePage from './routes/basePage';
import Welcome from './routes/welcome';
import Behandlungen from './routes/behandlungen';
import Stammzellen from './routes/behandlungen/stammzellen'
import Hyaluron from './routes/behandlungen/hyaluron';
import GoldenSkin from './routes/behandlungen/goldenSkin';
import EnergyVitaminPower from './routes/behandlungen/energyVitaminPower';
import Intensiv from './routes/behandlungen/intensiv';
import PureTreatment from './routes/behandlungen/pureTreatment';
import Tiefenreinigung from './routes/behandlungen/tiefenreinigung';
import Lymphdrainage from './routes/behandlungen/lymphdrainage';
import ApparativeBehandlungen from './routes/apparativeBehandlungen'
import Mesoporation from './routes/behandlungen/mesoporation';
import Mikrodermabrasion from './routes/behandlungen/mikrodermabrasion';
import Specials from './routes/behandlungen/specials';
import Extras from './routes/extras';
import HandFussKoerper from './routes/handFussKoerper';
import Navigation from './routes/navigation';
import Contact from './routes/contact'
import Impressum from './routes/impressum';

import Checkout from './routes/checkout';
import Successful from './routes/successful';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BasePage />}>
          <Route path="" element={<Welcome />} />
          <Route path="Behandlungen" element={<Behandlungen />} />
          <Route path="Behandlungen/Stammzellen" element={<Stammzellen />} />
          <Route path="Behandlungen/Hyaluronbehandlung" element={<Hyaluron />} />
          <Route path="Behandlungen/Golden-Skin" element={<GoldenSkin />} />
          <Route path="Behandlungen/Energy-Vitamin-Power" element={<EnergyVitaminPower />} />
          <Route path="Behandlungen/Intensivbehandlungen" element={<Intensiv />} />
          <Route path="Behandlungen/Pure-Behandlung" element={<PureTreatment />} />
          <Route path="Behandlungen/Tiefenreinigung" element={<Tiefenreinigung />} />
          <Route path="Behandlungen/lymphdrainage" element={<Lymphdrainage />} />
          <Route path="Apparative-Behandlungen" element={<ApparativeBehandlungen />} />
          <Route path="Apparative-Behandlungen/Mesoporation" element={<Mesoporation />} />
          <Route path="Apparative-Behandlungen/Microdermabrasion" element={<Mikrodermabrasion />} />
          <Route path="Apparative-Behandlungen/Specials" element={<Specials />} />
          <Route path="Extras" element={<Extras />} />
          <Route path="Hand-Fuss-Koerper" element={<HandFussKoerper />} />
          <Route path="Anfahrt" element={<Navigation />} />
          <Route path="Kontakt" element={<Contact />} />
          <Route path="Impressum" element={<Impressum />} />
          <Route path="Checkout" element={<Checkout />} />
          <Route path="Successful" element={<Successful />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
