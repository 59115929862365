import MainSlogan from "./components/mainSlogan";
import TreatmentIncludes from "./components/treatmentIncludes";

export default function GoldenSkin() {
  return (
    <div>
      <h1>Golden-Skin-Behandlung</h1>
      <p><b>„Gold-Kaviar-Hyaluron-Honig“</b></p>
      <MainSlogan text="Die exklusiven, ausgesuchten Wirkstoffe unterstützen optimal die Zellregeneration und verjüngen das Hautbild. Proteine, Spurenelemente & Mineralien aktivieren und regenerieren. Die Feuchtigkeitsbindung wird durch Honig unterstützt und wirkt gleichzeitig sanft beruhigend & klärend." />

      <p>
      In Zusammenspiel mit der enthaltenen „Meridiandruckpunkt-Massage“ wird das Hautergebnis optimiert.
      </p>

      <TreatmentIncludes
        list={['Hautdiagnose' ,'Reinigung' ,'Peeling je nach Hautbild' ,'Hautbedampfung' ,'Entfernen von Unreinheiten' ,'Augenbrauenkorrektur' ,'Augenpflege' ,'Meridian-Druckpunktmassage' ,'Wirkstoffampulle' ,'Spezialmaske' ,'verzaubernde Gesichts-, Hals-, Nacken- und Decolletemassage']}
        time={120}
        price={99}
      />
    </div>
  );
}
