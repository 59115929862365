export default function Impressum() {
    return (
        <div className="App">
            <h1>Impressum</h1>
            <p>
                Zauberschön Kosmetik <br />
                Carolin Cornet <br />
                Oderberger Str. 37 <br />
                10435 Berlin <br />
                +49 30 88062501 <br />
            </p>
            <h1>
                Haftungsausschluss
            </h1>
            <p>
                Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass Homepage-Betreiber durch die Angabe eines Links die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann – so das Landgericht – nur dadurch verhindert werden, dass man sich ausdrücklich von diesen Inhalten distanziert. Wir haben auf dieser Seite Links zu anderen Seiten im Internet gelegt. Für all diese Links gilt: Wir möchten ausdrücklich betonen, dass wir keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir uns hiermit ausdrücklich von den Inhalten aller gelinkten Seiten auf dieser Homepage und machen uns deren Inhalte nicht zu eigen.            </p>
        </div>
    );
}
