import { Navbar } from "react-bootstrap"
import Nav from 'react-bootstrap/Nav'

import { Container } from "react-bootstrap"
import { NavbarBrand } from "react-bootstrap"

export default function Footer() {
    return (
        <div>
            <footer>
                <Navbar className="mt-2">
                    <Container>
                        <NavbarBrand>Zauberschön Kosmetik</NavbarBrand>
                        <Nav.Link href="/Impressum">Impressum</Nav.Link>
                    </Container>
                </Navbar>
            </footer>
        </div>
    )
}
